var isMobile = jQuery.browser.mobile;

$(document).ready(function () {
  fnAnimateLinks();
  genSlideNew();

  AOS.init();

  setTimeout(function () {
    $(".menu-home").removeClass("d-none").addClass("animated fadeInUp");
  }, 600);

  var currentUrl = window.location.pathname;

  fnSlideHome();

  if (currentUrl.indexOf("sobre-nosotros") != -1) {
    $(".header-internas .navbar").sticky({topSpacing:0});
  }
  if (currentUrl.indexOf("prestaciones") != -1) {
    $(".header-internas .navbar").sticky({topSpacing:0});
  }
  if (currentUrl.indexOf("encontranos") != -1) {
    $(".header-internas .navbar").sticky({topSpacing:0});
    fnClickMapa();
    fnGenerateSlideProvincias();
    fnValidaForm();
  }

  if (currentUrl.indexOf("divisiones") != -1) {
    $(".header-internas .navbar").sticky({topSpacing:0});
    $("#sticky-wrapper").css("z-index", 999999);
    $("#sticky-wrapper .navbar").css("z-index", 999999);

    $(".navbar").css({
      "position": "absolute",
      "top": 0,
      "left": 0,
      "z-index": 10,
      "width": "100%"
    });
    fnSlideDivisiones();
  }

  // $('.owl-carousel').owlCarousel({
  //   items:7,
  //   stagePadding:100,
  //   dots:false,
  //   loop:true,
  //   margin:100,
  //   autoplay:true,
  //   autoplayTimeout:3000,
  //   autoplayHoverPause:true,
  //   autoWidth:true,
  //   pagination: false,
  //   responsive:{
  //       0:{
  //           items:1
  //       },
  //       600:{
  //           items:3
  //       },
  //       1000:{
  //           items:4
  //       }
  //   }
  // });

  $('.owl-carousel').owlCarousel({
    autoPlay: 3000,
    items : 5,
    pagination: false,
    itemsDesktop : [1199,3],
    itemsDesktopSmall : [979,3]
  });
  

});


var fnAnimateLinks = function () {
  $("a").on('click', function (event) {
    if (this.hash !== "") {
      event.preventDefault();
      var hash = this.hash;
      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 800, function () {
        window.location.hash = hash;
      });
    }
  });
}

var genSlideNew = function () {

  var $news = $('.carousel-novedades');
  var initialEnd = $('.carousel-novedades div').length;
  $news.flickity({
    arrowShape: 'M 0,50 L 60,00 L 50,30 L 80,30 L 80,70 L 50,70 L 60,100 Z',
    autoPlay: false,
    contain: true,
    groupCells: 1,
    pageDots: false,
    rightToLeft: true
  });
}

var fnSlideHome = function () {
  $.fn.preload = function () {
    this.each(function () {
      $('<img/>')[0].src = this;
    });
  }
  var images = Array("../../assets/img/bg-header-home.jpg",
    "../../assets/img/bg-header-home2.jpg");

  $([images[0], images[1]]).preload();

  var timeSlide = 4000;
  var currimg = 0;

  // $("#text-home1").removeClass("d-flex").hide();
  $("#text-home2").removeClass("d-flex").hide();

  function loadimg() {

    $('header.header').animate({ opacity: 1 }, 500, function () {
      $('header.header').animate({ opacity: 0.7 }, 800, function () {
        currimg++;
        if (currimg > images.length - 1) {
          currimg = 0;
        }
        if(currimg === 0){
          $("#text-home1").show();
          $("#text-home2").removeClass("d-flex").hide();
        }else{
          $("#text-home1").removeClass("d-flex").hide();
          $("#text-home2").show();
        }
        var newimage = images[currimg];
        $('header.header').css("background-image", "url(" + newimage + ")");
        $('header.header').animate({ opacity: 1 }, 400, function () {
          setTimeout(loadimg, timeSlide);
        });
      });
    });

  }
  setTimeout(loadimg, timeSlide);
}


var fnGenerateSlideProvincias = function (selProv) {
  console.log("selProv", selProv);
  var $sProv = $('.box-' + selProv + ' .slide-provincias');
  $sProv.flickity({
    arrowShape: 'M 0,50 L 60,00 L 50,30 L 80,30 L 80,70 L 50,70 L 60,100 Z',
    autoPlay: false,
    pageDots: false,
    contain: true,
    groupCells: true,
    adaptativeHeight: false
  });
}

var fnClickMapa = function () {
  setTimeout(function () {
    $(".points li").first().trigger("click");
  }, 400);
  $(document).on("click", ".point", function () {
    $(".point").removeClass("active");
    $(this).addClass("active");
    var selProv = $(this).attr("class").replace('point point-', '').replace(' active', '');
    $(".box-provincias").addClass("d-none");
    $(".box-" + selProv).removeClass("d-none").addClass("animated fadeIn");
    fnGenerateSlideProvincias(selProv);
  });
}

function isValidEmailAddress(emailAddress) {
  var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
  return pattern.test(emailAddress);
}

var fnValidaForm = function () {
  $(document).on("submit", "#form-contacto", function () {
    var valNombre = $("#nombre-y-apellido").val(),
      valMail = $("#email").val(),
      valConsulta = $("#consulta").val();
    if (valNombre === "") {
      $("#nombre-y-apellido").siblings(".invalid-feedback").show();
    } else if (valMail === "" || !isValidEmailAddress(valMail)) {
      $("#email").siblings(".invalid-feedback").show();
    } else if (valConsulta === "") {
      $("#consulta").siblings(".invalid-feedback").show();
    }
    $("#nombre-y-apellido").on("blur", function () {
      if ($(this).val() !== "") {
        $("#nombre-y-apellido").siblings(".invalid-feedback").hide();
      } else {
        $("#nombre-y-apellido").siblings(".invalid-feedback").hide();
      }
    });
    $("#email").on("blur", function () {
      if ($(this).val() !== "") {
        $("#email").siblings(".invalid-feedback").hide();
      } else {
        $("#email").siblings(".invalid-feedback").hide();
      }
    });
    $("#consulta").on("blur", function () {
      if ($(this).val() !== "") {
        $("#consulta").siblings(".invalid-feedback").hide();
      } else {
        $("#consulta").siblings(".invalid-feedback").hide();
      }
    });

    return false;

  });
}

var fnSlideDivisiones = function () {

  var $divisiones = $('.carousel-divisiones');

  $divisiones.flickity({
    arrowShape: 'M 0,50 L 60,00 L 50,30 L 80,30 L 80,70 L 50,70 L 60,100 Z',
    autoPlay: true,
    prevNextButtons: false,
    contain: true,
    groupCells: 1,
    pageDots: true,
    rightToLeft: false
  });

}